const getCWIDfromGraphAPI = ({ accessToken }) => {
  const cwidKey = "extension_99a30c616bfd42b2aa5b30ed9c5489ba_CWID";
  console.log(`Fetching CWID from Graph API`);
  return fetch(`https://graph.microsoft.com/v1.0/me/?$select=${cwidKey}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((resp) => resp.json())
    .then((data) => {
      console.log(`Response: ${JSON.stringify(data)}`);
      return data[cwidKey];
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export { getCWIDfromGraphAPI };
