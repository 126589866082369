import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import QRCode from "react-qr-code";
import { Container, Alert } from "react-bootstrap";
import { loginRequest } from "./authConfig";
import { useState } from "react";
import "./App.css";
import { getCWIDfromGraphAPI } from "./utils/cwid";

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const MainContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const activeAccount = instance.getActiveAccount();
  const [cwid, setCwid] = useState(null);
  const handleRedirect = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        prompt: "create",
      })
      .catch((error) => console.log(error));
  };



  if (isAuthenticated && activeAccount) {
    instance
      .acquireTokenSilent(loginRequest)
      .then((tokenResponse) => tokenResponse.accessToken)
      .then((accessToken) => {
        console.log(accessToken);
        getCWIDfromGraphAPI({ accessToken: accessToken }).then((cwid) => {
          setCwid(cwid);
        });
      });
  } else if (!isAuthenticated && inProgress === "none") {
    handleRedirect();
  }

  return (
    <div className="App">
      {isAuthenticated && cwid ? (
        <Container style={{ margin: "0px", height: "100%" }}>
          <QRCode size={256} style={{ maxWidth: "100%", height: "250px" }} value={cwid} viewBox={`0 0 256 256`} />
          <p>{cwid}</p>
          <Alert variant="primary">DO NOT SHARE YOUR QR CODE (DO NOT SEND A SCREENSHOT OR SCREEN RECORDING)</Alert>
        </Container>
      ) : isAuthenticated ? (
        // authenticated but no CWID
        <Alert variant="primary">No CWID Found</Alert>
      ) : null}
    </div>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <MainContent />
    </MsalProvider>
  );
};

export default App;
